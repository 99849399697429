<template>
  <div id="CommonFilesTable">
    <!-- <div class="top">
      <div><span></span> 附件</div>
    </div> -->
    <a-table :data="data" :bordered="{wrapper: true, cell: true}" :pagination="false">
      <template #columns>
        <a-table-column title="序号" width="72" :align="'center'" >
          <template #cell="{ rowIndex }">{{ rowIndex + 1 }}</template>
        </a-table-column>
        <a-table-column title="标题" data-index="fileName"></a-table-column>
        <a-table-column v-if="action" title="操作" width="100" :align="'center'">
          <template #cell="{ rowIndex, record }">
            <a-button v-if="action === 'delete'" type="text" @click="deleteClick(rowIndex)">删除</a-button>
            <a-button v-if="action === 'download'" type="text" @click="downLoadClick(record)">下载</a-button>
          </template>
        </a-table-column>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      require: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    deleteClick(index) {
      this.$emit('deleteClick', index)
    },
    downLoadClick(item) {
      window.location = this.$API.DownloadFile({
        fileName: item.fileName,
        path: item.url
      })
    }
  }
}
</script>

<style lang="scss">
  #CommonFilesTable {
    // padding: 0 10px 10px;
    margin-top: 20px;

    .top {
      padding: 15px 0;
      margin-top: 20px;

      & > div {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;

        & > span {
          width: 8px;
          height: 20px;
          background: #00ABAC;
          border-radius: 2px;
          display: inline-block;
          margin-right: 15px;
        }
      }

      .arco-input-wrapper .arco-input.arco-input-size-medium {
        padding: 0;
        font-size: 12px;
      }

      .arco-pagination-simple .arco-pagination-jumper .arco-pagination-jumper-input {
        width: 18px;
        border: none;
        display: inline;
      }

      .arco-pagination-jumper-total-page {
        display: inline-block;
        width: 18px;
        text-align: center;
      }

      .arco-pagination-size-mini .arco-pagination-item {
        min-width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }

    .arco-table {
      padding: 10px 0;
    }

    th, td {
      height: 40px;
      padding: 0;
    }

    .arco-table-container {
      border-radius: 0;
    }

    .arco-btn-size-medium {
      padding: 0;
      height: 20px;

      &:hover {
        background: transparent;
      }
    }
  }
</style>
