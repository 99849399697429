import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "articlesDetail" }
const _hoisted_2 = { style: {"margin-left":"20px"} }
const _hoisted_3 = ["innerHTML"]

import { onMounted, ref, getCurrentInstance } from 'vue'
  import CommonFilesTable from '@/components/CommonFilesTable.vue'
  import { useRoute } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      CommonFilesTable
    }
  },
  __name: 'Detail',
  setup(__props) {

  
  
  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const route = useRoute()
  let detail = ref(null)

  onMounted(() => {
    showPage()
  })

  function showPage() {
    globalProperties.$API.ArticleDetail({
      id: parseInt((route.params as any).id)
    }).then((res: any) => {
      if(res.data.code == 200) {
        detail.value = res.data.data
      }
    })
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_unref(detail) && (_unref(detail) as any).title), 1),
    _createElementVNode("p", null, [
      _createTextVNode("日期：" + _toDisplayString(_unref(detail) && (_unref(detail) as any).publishedDate) + " ", 1),
      _createElementVNode("span", _hoisted_2, "来源： " + _toDisplayString(_unref(detail) && (_unref(detail) as any).sourceName), 1)
    ]),
    _createElementVNode("div", {
      class: "w-e-text",
      innerHTML: _unref(detail) && (_unref(detail) as any).content
    }, null, 8, _hoisted_3),
    (_unref(detail) && (_unref(detail) as any).attachmentList && (_unref(detail) as any).attachmentList.length > 0)
      ? (_openBlock(), _createBlock(CommonFilesTable, {
          key: 0,
          data: (_unref(detail) as any).attachmentList,
          action: "download"
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ]))
}
}

})